<template>
  <div class="chatApp">
    <div class="join-container">
      <header class="join-header">
        <h1>
          <strong>Oppss..</strong>
        </h1>
        <h4>No Access.</h4>
        <h5>You have entered a page without your permission.</h5>
        <a href="https://gowawe.com" class="btn btn-warning my-3"
          >Return GoWaWe Home Page</a
        >
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: "n404NotFound",
};
</script>
